<template>
  <div class="k-9sptci" v-loading="loading">
    <!-- 查询数据 -->
    <div slot class="clearfix">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="门派ID">
          <el-input
            placeholder="请输入门派ID"
            v-model="searchForm.sect_id"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="门派名称">
          <el-input
            placeholder="请输入门派名称"
            v-model="searchForm.name"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchForm.type" placeholder="请选择查询条件">
            <el-option label="主门" :value="1"></el-option>
            <el-option label="分门" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="当前状态">
          <el-select
            @change="handleDepot()"
            v-model="searchForm.status"
            placeholder="请选择查询条件"
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="回收站" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          @click="handleQuery()"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >查 询</el-button
        >
        <el-button
          @click="addUser"
          style="margin-left: 20px; margin-right: 20px;padding: -9px 30px; font-size: 15px"
          type="primary"
          >新增门派</el-button
        >
        <el-form-item>
          <importButton
            :import-url="`/admin/import/new/import_sect_alliance`"
            :status-url="`/admin/import/new/import_sect_alliance_status`"
            :data="{ match_id: match_id }"
            @refushList="handleQuery()"
          ></importButton>
        </el-form-item>
        <el-form-item>
          <importHistoryButton
            :history_url="`/admin/import/new/import_sect_alliance_history`"
            :import_template="`import_sect_alliance_new`"
          ></importHistoryButton>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="dataTable"
      style="width: 100%"
      ref="table"
      :row-key="searchForm.status == 1 ? 'sect_id' : '_id'"
      border
      lazy
      :load="loadData"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="sect_id" label="门派id" width="180">
      </el-table-column>
      <el-table-column prop="name" label="门派名称" width="180">
      </el-table-column>
      <el-table-column
        label="分门对应的主门"
        width="200"
        v-if="searchForm.status == 0"
      >
        <template slot-scope="{ row }">
          <span>
            {{ row.type == 2 ? row.link_sect_id[0] : "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="200">
        <template slot-scope="{ row }">
          <span>
            {{ row.type == 1 ? "主门" : "分门" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="当前状态" width="200">
        <template slot-scope="{ row }">
          <span>
            {{ row.status == 0 ? "回收站" : "正常" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑门派</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="
              handleDelete(scope.row._id, scope.row.status, scope.row)
            "
            >{{ scope.row.status == 1 ? "删除" : "恢复" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="view_type == 'add' ? '新增门派联盟' : '修改门派联盟'"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item label="类别" prop="type">
          <el-select
            :disabled="view_type == 'edit'"
            v-model="userData.type"
            placeholder="请选择查询条件"
            @change="changeType()"
          >
            <el-option label="主门" :value="1"></el-option>
            <el-option label="分门" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="门派ID"
          prop="sect_id"
          :rules="[
            {
              required: true,
              message: '请输入门派ID',
              trigger: ['blur'],
            },
            {
              max: 6,
              message: '最多6个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.sect_id"
            placeholder="请输入门派ID"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="门派名称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '请输入门派名称',
              trigger: ['blur'],
            },
            {
              max: 25,
              message: '最多25个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.name"
            placeholder="请输入门派名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分门"
          prop="link_sect_id"
          v-if="userData.type == 1"
        >
          <el-select
            v-model="userData.link_sect_id_part"
            filterable
            remote
            multiple
            placeholder="请输入6位门派编号"
            :remote-method="searchHandler"
          >
            <el-option
              v-for="item in options"
              :key="item.sect_id"
              :label="item.name"
              :value="item.sect_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="主门"
          prop="link_sect_id"
          v-if="userData.type == 2"
        >
          <el-select
            :disabled="view_type == 'edit'"
            v-model="userData.link_sect_id_main"
            filterable
            remote
            placeholder="请输入6位门派编号"
            :remote-method="searchHandler"
          >
            <el-option
              v-for="item in options"
              :key="item.sect_id"
              :label="item.name"
              :value="item.sect_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { MessageBox } from "element-ui";
import importButton from "../../match/components/importButton";
import importHistoryButton from "../../match/components/importHistoryButton";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  components: {
    importButton,
    importHistoryButton,
  },
  data() {
    return {
      maps: new Map(),
      options: [],
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        status: 1,
        type: 1,
      },
      dataTable: [],
      userVisible: false,
      view_type: "add",
      userData: {
        link_sect_id_main: "",
        type: 1,
        _id: "",
        sect_id: "",
        name: "",
        link_sect_id_part: [],
      },
      match: false,
    };
  },
  methods: {
    // 添加初始化
    addUser() {
      this.view_type = "add";
      this.userData._id = "";
      this.userData.sect_id = "";
      this.userData.name = "";
      this.userData.link_sect_id_main = "";
      this.userData.link_sect_id_part = [];
      this.userData.link_sect_id = "";
      this.userVisible = true;
      this.options = [];
    },
    //编辑赋值
    async handleEdit(row) {
      this.options = [];
      this.userData.link_sect_id_part = [];
      this.view_type = "edit";
      this.userData._id = row._id;
      const { data } = await this.$http.operate.sect_alliance_detail({
        sect_id: row.sect_id,
      });
      this.userData._id = data._id;
      this.userData.type = data.type;
      this.userData.sect_id = data.sect_id;
      this.userData.name = data.name;
      //如果是主门
      if (data.type == 1) {
        this.options = data.link_sect;
        if (data.link_sect) {
          data.link_sect.forEach((item) => {
            this.userData.link_sect_id_part.push(item.sect_id);
          });
        }
      } else {
        //主门只有一个
        this.options = data.link_sect;
        if (data.link_sect) {
          // 如果type==2的时候，我的link_sect一定是主门，而主门一定只有一条
          this.userData.link_sect_id_main = data.link_sect[0].sect_id;
        }
      }

      this.userVisible = true;
    },
    //添加banner
    saveBanner() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;

          //1 主门
          if (this.userData.type == 1) {
            this.userData.link_sect_id = this.userData.link_sect_id_part.toString();
          } else {
            this.userData.link_sect_id = this.userData.link_sect_id_main;
          }
          let saveData = {
            _id: this.userData._id,
            link_sect_id: this.userData.link_sect_id,
            name: this.userData.name,
            type: this.userData.type,
            sect_id: this.userData.sect_id,
          };
          let { data, code } = await this.$http.operate.new_save_sect_alliance(
            saveData
          );
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.userVisible = false;
            if (this.view_type == "edit") {
              //如果是分门
              if (this.userData.type == 2) {
                //拿到主门id
                let parentId = this.userData.link_sect_id;
                this.handleLoadData(parentId);
              } else {
                //主门走查询
                //如果是主门,新增分门，那么我应该去查询主门下的详情分门数据及主门数据
                //拿到主门id
                let parentId = this.userData.sect_id;
                this.handleLoadData(parentId);
              }
            }

            this.handleQuery();
          }
        }
      });
    },
    handleLoadData(parentId) {
      if (parentId != "") {
        if (this.maps.get(parentId) != undefined) {
          const { tree, treeNode, resolve } = this.maps.get(parentId);
          this.$set(
            this.$refs.table.store.states.lazyTreeNodeMap,
            parentId,
            []
          );
          this.loadData(tree, treeNode, resolve);
        }
      }
    },
    //删除
    async handleDelete(_id, status, row) {
      try {
        let status_title = status == 1 ? "是否删除" : "是否恢复";
        await MessageBox.confirm(status_title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        let new_status = status == 0 ? 1 : 0;
        const { code } = await this.$http.operate.new_update_status({
          _id: _id,
          status: new_status,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          //在回收站的时候
          if (this.searchForm.status == 0) {
            this.handleQuery();
          } else {
            //如果是分门,首先要拿到主门id
            if (row.type == 2) {
              //拿到主门id
              let parentId = row.link_sect_id[0];
              const { tree, treeNode, resolve } = this.maps.get(parentId);
              this.$set(
                this.$refs.table.store.states.lazyTreeNodeMap,
                parentId,
                []
              );
              this.loadData(tree, treeNode, resolve);
            } else {
              //主门走查询
              this.handleQuery();
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      //如果状态是正常
      if (this.searchForm.status == 1) {
        //默认显示主门
        this.searchForm.type = 1;
      } else {
        this.searchForm.type = "";
      }

      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const {
        data,
        errorCode,
      } = await this.$http.operate.new_sect_alliance_list({
        ...this.searchForm,
        pageNum,
      });
      this.loading = false;
      this.dataTable = data.data;
      //如果是主门且不在回收站
      if (this.searchForm.status != 0) {
        this.dataTable.forEach((item) => {
          if (item.type == 1 && item.link_sect_id.length != 0) {
            item.hasChildren = true;
          }
        });
      }

      this.pager = data.pager;
    },
    //切换类型
    changeType() {
      this.userData.link_sect_id_main = "";
      this.userData.link_sect_id_part = [];
      this.options = [];
    },
    //搜索门派id
    async searchHandler(query) {
      if (query !== "" && query.length == 6) {
        let { data, errorCode } = await this.$http.match.getSectMembers(
          query,
          false
        );
        if (!data) {
          this.options = [];
        } else {
          this.options = [
            {
              sect_id: data.sect_id,
              name: data.sect_name,
            },
          ];
        }
      }
    },
    //懒加载数据
    async loadData(tree, treeNode, resolve) {
      const parentId = tree.sect_id;
      this.$set(this.$refs.table.store.states.lazyTreeNodeMap, parentId, []);
      this.maps.set(parentId, { tree, treeNode, resolve });
      if (tree.sect_id) {
        const { data } = await this.$http.operate.sect_alliance_detail({
          sect_id: tree.sect_id,
        });
        //显示分门数据
        this.$nextTick(() => {
          resolve(data.link_sect);
        });
      }
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  beforeUpdate() {
    this.$nextTick(() => {
      //在数据加载完，重新渲染表格
      this.$refs["table"].doLayout();
    });
  },
  async mounted() {
    //查询列表
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
